
//在线记账
export const AccountStatement = [
  {
      prop: "incomePaymentId",
      label: "编号",
      width: 100,

  },
  {
      prop: "fileType",
      label: "收支类型",
      width: 100,
      template: true,
  },

  {
      prop: "files",
      label: "图片预览",
      template: true,

  },
  {
      prop: "remark",
      label: "备注",

  },
  {
      prop: "createTime",
      label: "上传时间",
      template: true,

  },
]



